<template>
  <div class="page">
    <!-- 引入的返回头组件 -->
    <titlebar :name="name" />
    <div class="list_box">
      <div>条码</div>
      <div>商品名称</div>
      <div>分类</div>
      <div>数量</div>
      <div>小计</div>
    </div>
    <div class="overflow_y">
      <div class="list_box list_index" v-for="(item, index) in proList" :key="index">
        <div>{{ item.bar_code }}</div>
        <div>{{ item.goods_name }}</div>
        <div>{{ item.c_name }}</div>
        <div>{{ item.number }}</div>
        <div>{{ item.total_price }}</div>
      </div>
    </div>
    <div class="null_box"></div>
    <div class="btm_btn_box">
      <van-row class="btm_btn_box_row">
        <van-col span="12">
          <div style="width: fit-content;">
            <van-pagination v-model="page" :total-items="total" :items-per-page="limit" @change="pageChange" force-ellipses />
          </div>
        </van-col>
        <van-col span="6">
          <div class="btm_text">{{ total }}件，总价¥{{ order_total_price }}</div>
        </van-col>
        <van-col span="6">
          <van-button class="btm_btn">打印</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
export default {
  created() {
    this.name = this.$route.query.name || ''; //拿到传过来的标题
    console.log("name:", this.name);
    this.getList();
  },
  components: {
    titlebar: titlebar,
  },
  data() {
    return {
      proList: [],
      page: 1,
      limit: 10,
      total: 0,
      order_total_price: 0
    };
  },
  methods: {
    getList() {
      let data = {
        count: 100,
        limit: "20",
        page: "1",
        list: [],
        order_total_price: 0.06
      }
      this.proList = data.list;
      this.total = data.count;
      this.order_total_price = data.order_total_price;
      this.$api.handoverOrder({
        shop_id: localStorage.getItem('shop_id'),
        limit: this.limit,
        page: this.page
      }).then(res => {
        this.proList = res.data.list;
        this.total = res.data.count;
        this.order_total_price = res.data.order_total_price;
      })
    },
    // 页码改变
    pageChange(e) {
      this.page = e;
      console.log('销售商品列表，当前页码：', this.page);
      this.getList();
    }
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 192rem;
  height: 100%;
  overflow-x: hidden;

  .overflow_y {
    // height: 76.1rem;
    height: calc(100vh - 33rem);
    overflow-y: scroll;
  }
  .overflow_y::-webkit-scrollbar {
    display: none;
  }
  .list_box {
    width: 178.6rem;
    height: 8.5rem;
    border-bottom: 0.1rem solid #e5e5e5;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(5, 20%);
    align-items: center;
    font-size: 2.6rem;
    font-weight: bold;
    color: #333;
  }
  .list_index {
    font-size: 2rem;
    font-weight: 400;
  }
  .null_box {
    width: 20rem;
    height: 12.7rem;
  }
  .btm_btn_box {
    position: fixed;
    bottom: 0;
    // width: 192rem;
    height: 12.7rem;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    background-color: #fff;
    width: 178.6rem;
    left: 6.7rem;
    display: flex;
    align-items: center;
    .btm_btn_box_row {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  .btm_text {
    font-size: 2.2rem;
    margin-left: 7.5rem;
  }
  .btm_btn {
    width: 19.4rem;
    height: 6rem;
    color: #fff;
    background-color: #1588F5;
    // margin-right: 7.2rem;
  }
}
</style>
